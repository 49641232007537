import gql from 'graphql-tag'
import { expeditionDetailFields } from './expeditionGetQuery'

const expeditionCancelQuery = gql`
  query expeditionCancel($id: String!) {
    expeditionCancel(id: $id, input: {}) @rest(type: "Expedition", method: "PUT", path: "expedition/{args.id}/cancel") {
      ${expeditionDetailFields}
    }
  }
`

export default expeditionCancelQuery
