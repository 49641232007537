import gql from 'graphql-tag'

const carriersRemoveQuery = gql`
  query carriersRemove($organisationId: number, $carriers: Array) {
    carriersRemove(organisationId: $organisationId, input: { carriers: $carriers })
      @rest(type: "carriersRemove", method: "PUT", path: "organisation/{args.organisationId}/carrier-remove") {
      carriers
    }
  }
`
export default carriersRemoveQuery
