import gql from 'graphql-tag'

const stockAdviceGetQuery = gql`
  query stockAdviceGet($id: String!) {
    stockAdviceGet(id: $id) @rest(type: "StockAdviceResult", method: "GET", path: "stock-advice/{args.id}") {
      id
      user
      organisation
      warehouse
      wms
      supplier
      packagingUnit
      countOfUnits
      createdAt
      status
      manualInput
      items
      note
      internalId
      wmsInternalId
      expectedAt
      createdAt
      ref1
      ref2
      ref3
      changedAt
    }
  }
`

export default stockAdviceGetQuery
