/**
 * build filters from criteria for API V3
 */
import { Criteria } from './buildCriteria'
import { ApiSorting } from './buildSorting'
import merge from 'lodash/merge'

export type FilterV3 = {
  filters: Record<string, any>
}

const mapValue = (value) => {
  if (value?.gte && value?.lt) {
    return { from: value.gte, to: value.lt }
  }
  if (value?.like) {
    if (value?.like.startsWith('%') & value?.like.endsWith('%')) {
      return { contains: value?.like?.replaceAll('%', '') }
    }
    if (value?.like.startsWith('%')) {
      return { endsWith: value?.like?.replaceAll('%', '') }
    }
    return { startsWith: value?.like?.replaceAll('%', '') }
  }
  if (value?.notLike) {
    return { notContains: value?.notLike?.replaceAll('%', '') }
  }
  if (value?.in) {
    return value?.in
  }
  return value
}

const mapSorting = (sorting?: ApiSorting[]) => {
  const sort = sorting?.[0]
  const field = sort?.field
  const order = sort?.order
  if (field && order) {
    return `${order === 'desc' ? '-' : ''}${field}`
  }
  return undefined
}

const dotPathToNestedObject = (pathObject: Criteria) => {
  return Object.entries(pathObject).reduce((acc, [path, value]) => {
    const [last, ...paths] = path.split('.').reverse()
    const nested = paths.reduce((acc, el) => ({ [el]: acc }), { [last]: mapValue(value) } as object)
    return merge(acc, nested)
  }, {})
}

const buildFilters = (criteria: Criteria, sorting?: ApiSorting[], limit?: number, offset?: number): FilterV3 => {
  const variables = {}
  Object.assign(variables, {
    filters: dotPathToNestedObject(criteria),
    orderBy: mapSorting(sorting),
    pagination: { limit, offset },
  })
  return variables as FilterV3
}

export default buildFilters
