import { ApolloClient } from '@apollo/client'
import { takeLatest, select, put } from 'redux-saga/effects'
import { actionTypes } from './'
import eshopBaselinkerGet from '@queries/eshopBaselinkerGet'
import { BASELINKER_CORRUPTED_ERROR } from '@typings/entities/Eshop'
import createFetchSaga from '@utils/store/createFetchSaga'
import { actionTypes as eshopActionTypes } from '@store/eshops'
import { selectors as routerSelectors } from '@store/connectedRouter'
import { push } from 'connected-react-router'
import pages from '@pages/index'

const fetchBaselinkerCredentials = async (client: ApolloClient<any>, action: Action) => {
  if (!action.payload) {
    return
  }

  return client
    .query({
      query: eshopBaselinkerGet,
      variables: { id: action.payload },
      context: {
        suppressError: (err: string, errCode: number): boolean => {
          return errCode == BASELINKER_CORRUPTED_ERROR
        },
      },
    })
    .then(({ data }) => ({
      data: data?.eshopBaselinkerGet,
    }))
    .catch((e: any) => {
      const error = String(e?.response?.data?.message || e?.message || e)
      console.error(error)
    })
}

function* handleEshopSelect(action): Generator {
  const path = (yield select(routerSelectors.pathnameSelector)) as string
  const isBaseLinker = path.includes('/settings/baselinker/')
  yield createFetchSaga(actionTypes.run, fetchBaselinkerCredentials)(action)

  if (isBaseLinker) {
    yield put(push(pages.sharedBaselinker.route.toUrl()))
  }
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield takeLatest(eshopActionTypes.setSelectedEshop, handleEshopSelect)
  yield takeLatest(actionTypes.run, createFetchSaga(actionTypes.run, fetchBaselinkerCredentials))
}
