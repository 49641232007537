import { ApolloClient } from '@apollo/client'
import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import productStocksGetQuery, { productStocksGetFiltersQuery } from '@queries/productStocksGetQuery'
import toSelectOption from '@utils/toSelectOption'
import { sagaDebounce } from '@store/helpers'

const fetchList = (client: ApolloClient<any>, action: Action): Promise<any> => {
  const criteria = {
    ...(action.payload?.criteria || {}),
    'product.type': { eq: 'physical' },
  }
  return client
    .query({ query: productStocksGetQuery, variables: { ...action.payload, criteria } })
    .then(({ data }) => ({ data: data?.productStocksGet }))
}

const fetchFilters = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const productStocksFilters = await client
    .query({
      query: productStocksGetFiltersQuery,
      variables: {
        path: action.payload.orgId ? `/${action.payload.orgId}` : '',
      },
    })
    .then(({ data }) => data?.productStocksGetFilters)
  return {
    data: {
      warehouse: productStocksFilters.warehouses.map(toSelectOption('id', 'name')),
    },
  }
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return // dont run on SSR
  yield sagaDebounce(200, actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
  yield takeLatest(actionTypes.productStocksFilters.run, createFetchSaga(actionTypes.productStocksFilters.run, fetchFilters))
}
