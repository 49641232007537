import gql from 'graphql-tag'

const reservationsGetQuery = gql`
  query reservationsGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object, $extra: Object) {
    reservationsGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, extra: $extra, nested: true })
      @rest(type: "ReservationsResult", method: "POST", path: "reservation/list") {
      results
      paging
    }
  }
`

export default reservationsGetQuery
