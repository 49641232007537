import { ApolloClient } from '@apollo/client'
import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import carriersGetQuery from '@queries/carriersGetQuery'
import carrierServicesGetQuery from '@queries/carrierServicesGetQuery'
import { Carrier, CarrierService, ModifiedCarrier } from '@typings/entities/Carrier'

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const { carriers } = await client
    .query({ query: carriersGetQuery, variables: action.payload })
    .then(({ data }) => ({ carriers: data?.carriersGet }))

  const carrierIds = carriers.results.map((carrier: Carrier) => carrier.id)

  const services =
    carrierIds.length < 1
      ? []
      : await client
          .query({ query: carrierServicesGetQuery, variables: { criteria: { carrier: { in: carrierIds } } } })
          .then(({ data }) => data?.carrierServicesGet?.results as CarrierService[])

  const results = carriers.results.map((carrier: Carrier) => ({
    ...carrier,
    serviceValues: services.filter((service) => service.carrier === carrier.id),
  })) as ModifiedCarrier[]

  return { data: { ...carriers, results } }
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield takeLatest(actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
}
