import gql from 'graphql-tag'

const byznysUserCreateQuery = gql`
  query byznysUserCreate($username: String!, $eshopId: String!) {
    byznysUserCreate(input: { username: $username, eshop: $eshopId })
      @rest(type: "ByznysUserCreateResult", method: "POST", path: "byznys-user") {
      eshop
      id
      plainToken
      username
    }
  }
`

export default byznysUserCreateQuery
