import React from 'react'
import LayoutSwitcher, { LayoutType } from './LayoutSwitcher'
import { useLocation } from 'react-router'

const getLayoutTypeFromPathname = (pathname: string): LayoutType => {
  if (/(settings)/.test(pathname)) return 'Settings'
  if (/^\/admin(\/|$)/.test(pathname)) return 'Admin'
  if (/^\/user(\/|$)/.test(pathname)) return 'User'
  return null
}

type Props = {
  children: React.ReactNode
}

const Container = ({ children }: Props): JSX.Element => {
  const { pathname } = useLocation()

  return <LayoutSwitcher type={getLayoutTypeFromPathname(pathname)}>{children}</LayoutSwitcher>
}

export default Container
