import { delay, put, call } from 'redux-saga/effects'
import { actions as statusRowsActions } from '@store/statusRows'
import getRuntimeConfig from '@utils/getRuntimeConfig'
import { t } from '@lingui/macro'

async function getVersion(): Promise<string | void> {
  try {
    const promise: Promise<any> = fetch('/app-manifest.json?t=' + Number(new Date()))
    const response = await promise
    const data = await response.json()
    return data.appVersion
  } catch (e) {
    console.log('Error while checking version', e)
  }
}

function* checkVersionPeriodically(): Generator {
  const checkPeriod = 1 * 60 * 1000 // 1 min
  const currentAppVersion: string = getRuntimeConfig('APP_VERSION')
  if (!currentAppVersion) return // no current version available, nothing to compare
  let lastRemoteAppVersion
  let shouldCheck = true

  while (shouldCheck) {
    yield delay(checkPeriod)
    const remoteAppVersion = yield call(getVersion)
    if (remoteAppVersion === lastRemoteAppVersion) {
      // Last two versions are equal
      if (remoteAppVersion !== currentAppVersion) {
        shouldCheck = false

        console.log(`You should update, your version is ${currentAppVersion}, remote is ${remoteAppVersion}`)

        yield put(
          statusRowsActions.push(
            t({ id: 'status.newVersion.title', message: 'New version available.' }),
            {
              default: t({ id: 'status.newVersion.text', message: 'You can update to the latest version.' }),
              intent: 'info',
              onClick: () => window.location.reload(),
            },
            null,
          ),
        )
      }
    }
    lastRemoteAppVersion = remoteAppVersion
  }
}

export default checkVersionPeriodically
