import ThemeProvider from '@mailstep/design-system/ui/ThemeProvider'
import React from 'react'
import { getLanguage } from '@utils/language'
import debug from 'debug'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import { Route, Switch } from 'react-router-dom'
import ScrollToTop from '@components/elements/ScrollToTop'
import Layout from './components/layouts'
import Error500 from '@pages/Error500'
import Error404 from '@pages/Error404'
import routes from '@pages/index'
import RouteStatus from '@utils/components/RouteStatus'
import { Provider as AclProvider } from '@components/blocks/Acl'
import { catalogs, plurals } from './locales/catalogs'
import { Provider as PrintingProvider } from '@components/blocks/Printing'

type AppProps = {}

type AppState = {
  error?: Error
}

const appDebug = debug('app')
const routesArray = Object.entries(routes)

const ignoreErrors = [
  "Failed to execute 'removeChild' on 'Node'", // The error is caused by translators in the browser or their plugins, which modify the DOM and cause the application to crash. We can ignore it
]

class App extends React.Component<AppProps, AppState> {
  public static getDerivedStateFromError(error: Error): {} {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  constructor(props: AppProps) {
    super(props)
    this.state = {}
  }

  public componentDidCatch(error: Error, errorInfo: any): void {
    appDebug('App did catch', error, errorInfo)
    Sentry.captureException(error)
    this.setState({ error })
  }

  public render(): React.ReactNode {
    const language = getLanguage()
    i18n.loadLocaleData(language, { plurals: plurals[language] })
    i18n.load(catalogs)
    i18n.activate(language)

    if (this.state.error) {
      if (!ignoreErrors.find((ignoredError) => String(this.state.error).includes(ignoredError))) {
        return (
          <I18nProvider i18n={i18n}>
            <ThemeProvider theme="default" preflight={false} language={language}>
              <RouteStatus statusCode={500}>
                <Error500 error={this.state.error} />
              </RouteStatus>
            </ThemeProvider>
          </I18nProvider>
        )
      }
    }
    return (
      <I18nProvider i18n={i18n}>
        <ThemeProvider theme="default" preflight={false} language={language}>
          <PrintingProvider>
            <AclProvider>
              <Layout>
                <ScrollToTop />
                <Switch>
                  {routesArray.map(([key, route]) => (
                    <Route key={key} {...route.extraProps} path={route.route.path} render={route.render} />
                  ))}
                  <RouteStatus statusCode={404}>
                    <Route component={Error404} />
                  </RouteStatus>
                </Switch>
              </Layout>
            </AclProvider>
          </PrintingProvider>
        </ThemeProvider>
      </I18nProvider>
    )
  }
}

export default App
