import { History } from 'history'
import { applyMiddleware, compose, createStore, Store, combineReducers, Reducer, Middleware } from 'redux'
import { persistReducer, createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // localStorage for web
import createSagaMiddleware from 'redux-saga'
import omit from 'lodash/omit'

const persistTransform = createTransform(null, (state) => omit(state as any, ['processDialog', 'quickAccessDialogs']))

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['commonGrid', 'organisation', 'ui', 'baselinkerCredentials'],
  transforms: [persistTransform],
}

export type AppStore = Store & {
  injectReducer: (key: string, asyncReducer: Function) => any
  injectSaga: (key: string, saga: Function) => any
  setSagaContext: (context: {}) => void
}

const sagaContext = {} as any
const effectMiddleware = (next: Function) => (effect: any) => {
  if (effect.type === 'GET_CONTEXT' && /^@.+/.test(effect.payload)) {
    const name = effect.payload.slice(1)
    const value = sagaContext.context[name]
    Promise.resolve().then(() => next(value))
    return
  }
  return next(effect)
}

const createRootReducer = (staticReducers: object, asyncReducers: object = {}): Reducer =>
  combineReducers({
    ...staticReducers,
    ...asyncReducers,
  })

const patchAsyncReducers = (asyncReducers: {}, reducers: {}, initialState: {} = {}): {} => {
  // Preserve initial state for not-yet-loaded reducers (by adding dummy reducer)
  Object.keys(initialState).forEach((item) => {
    /* @ts-ignore */
    if (typeof reducers[item] === 'undefined') {
      /* @ts-ignore */
      asyncReducers[item] = (state: object | null = null): any => state
    }
  })
  return asyncReducers
}

const setupInjectors = (asyncReducers: {}, store: AppStore, history: any, createReducers: Function): void => {
  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key, asyncReducer: any) => {
    /* @ts-ignore */
    asyncReducers[key] = asyncReducer
    const reducers = createReducers(history)
    store.replaceReducer(createRootReducer(reducers, asyncReducers))
  }
}

const setupSagaInjector = (store: AppStore, runSaga: Function, rootSaga: Function): void => {
  // Create a dictionary to keep track of injected sagas
  const injectedSagas = new Map()

  const isInjected = (key: string): boolean => injectedSagas.has(key)

  store.injectSaga = (key: string, saga: Function): void => {
    // We won't run saga if it is already injected
    if (isInjected(key)) return

    // Sagas return task when they executed, which can be used
    // to cancel them
    const task = runSaga(saga)

    // Save the task if we want to cancel it in the future
    injectedSagas.set(key, task)
  }

  // Inject the root saga as it a staticlly loaded file,
  store.injectSaga('root', rootSaga)
}

export default function configureStore(
  initialState: object = {},
  history: History,
  createReducers: (h: History) => object,
  createMiddlewares: (h: History) => Middleware[],
  rootSaga: Function,
): AppStore {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const sagaMiddleware = createSagaMiddleware({ effectMiddlewares: [effectMiddleware] })
  const middlewares = [sagaMiddleware, ...createMiddlewares(history)]
  const enhancers = [applyMiddleware(...middlewares)]

  /* eslint-disable prettier/prettier */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose
  /* eslint-enable */

  // reducers injection
  let asyncReducers = {}
  const reducers = createReducers(history)
  asyncReducers = patchAsyncReducers(asyncReducers, reducers, initialState)
  const rootReducer = createRootReducer(reducers, asyncReducers)
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  const store: AppStore = createStore(persistedReducer, initialState, composeEnhancers(...enhancers))
  setupInjectors(asyncReducers, store, history, createReducers)

  // sagas injection
  setupSagaInjector(store, sagaMiddleware.run, rootSaga(store))
  //sagaMiddleware.run(rootSaga) //dont call run, is has been runned in setupSagaInjector()

  store.setSagaContext = (context: object) => {
    sagaContext.context = context
  }

  return store
}
