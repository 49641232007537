import gql from 'graphql-tag'

const productCreateQuery = gql`
  query productCreate($productId: String!, $image: String!, $originalName: String, $description: String) {
    productCreate(productId: $productId, input: { imageFile: $image, originalName: $originalName, description: $description })
      @rest(method: "PUT", path: "product/{args.productId}/image/upload") {
      id
      url
      urlSmall
    }
  }
`

export default productCreateQuery
