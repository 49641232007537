import createAxiosRequest from '@utils/createAxiosRequest'
import { DateTime } from 'luxon'
import getRuntimeConfig from '@utils/getRuntimeConfig'

const stockAdviceImport = (values: object): Promise<any> => {
  const apiBaseUrl = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')
  const endpoint = `stock-advice/import-item`
  const formData = new FormData()
  formData.append('file', values.fileInput)
  formData.append(
    'stock_advice',
    JSON.stringify({
      organisation: values.organisation,
      wms: values.wms,
      warehouse: values.warehouse,
      supplier: values.supplier,
      packagingUnit: values.packagingUnit,
      countOfUnits: values.countOfUnits,
      note: values.note,
      internalId: values.internalId,
      expectedAt: `${DateTime.fromJSDate(values.expectedAt).toISO({ suppressMilliseconds: true })}`,
    }),
  )
  return createAxiosRequest({
    method: 'POST',
    url: `${apiBaseUrl}${endpoint}`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export default stockAdviceImport
