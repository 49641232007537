import { ApolloClient } from '@apollo/client'
import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import usersGetQuery from '@queries/usersGetQuery'

const fetchUsers = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  let usedOrganisationId
  if (action.payload?.organisation) {
    usedOrganisationId = action.payload?.organisation
  }

  if (!usedOrganisationId) {
    throw new Error('fetching from users without organisation')
  }
  return client
    .query({
      query: usersGetQuery,
      variables: { nested: true, criteria: { organisations: { eq: usedOrganisationId } } },
    })
    .then(({ data }) => ({ data: data?.usersGet }))
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield takeLatest(actionTypes.run, createFetchSaga(actionTypes.run, fetchUsers))
}
