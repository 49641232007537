import gql from 'graphql-tag'

const countriesGetQuery = gql`
  query countriesGet {
    countriesGet(input: {}) @rest(type: "CountriesGet", method: "POST", path: "country/list") {
      results
    }
  }
`

export default countriesGetQuery
