import { actions as statusRowsActions } from '@store/statusRows'
import { TokenType, getRefreshTokenFromLocalStorage } from '@utils/getAuthorizationToken'
import { put, call, select, delay, getContext, spawn } from 'redux-saga/effects'
import { TokenData } from '@typings/UI'
import refreshTokenQuery from '@queries/refreshTokenQuery'
import { ApolloClient, ApolloQueryResult } from '@apollo/client'
import { selectors as routerSelectors } from '@store/connectedRouter'
import { getTokenFromLocalStorage, isTokenExpired, isPageWithoutToken } from '@utils/getAuthorizationToken'

type SetAuthAction = ({ token, exp }: TokenData) => Action

const checkTokenPeriodically = (tokenType: TokenType, logoutAction: Action, onSetAuth: SetAuthAction) =>
  function* (): Generator {
    while (true) {
      yield delay(10000)
      try {
        if (getTokenFromLocalStorage(tokenType) && isTokenExpired(tokenType, 60)) {
          const success = yield call(renewToken(tokenType, onSetAuth))

          if (!success) {
            const path = (yield select(routerSelectors.pathnameSelector)) as string

            if (!isPageWithoutToken(path)) {
              yield put(
                statusRowsActions.push('Your token has expired.', { default: 'You have been logged out.', intent: 'warning' }),
              )
              yield put(logoutAction)
            }
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

const renewToken = (tokenType: TokenType, onSetAuth: SetAuthAction) =>
  function* (): Generator {
    try {
      const client = (yield getContext('@apolloClient')) as ApolloClient<any>
      const refreshToken = getRefreshTokenFromLocalStorage(tokenType)

      if (!refreshToken) {
        return false
      }

      const response = (yield call(() =>
        client.query({ query: refreshTokenQuery, variables: { refreshToken }, context: { suppressError: () => true } }),
      )) as ApolloQueryResult<{ newToken: TokenData }>

      const { token, exp } = response?.data?.newToken
      yield put(onSetAuth({ token, exp }))

      return true
    } catch (e) {}

    return false
  }

const init = (
  tokenType: TokenType,
  onSuccess: () => Generator,
  logoutAction: Action,
  completeAction: Action,
  onSetAuth: SetAuthAction,
) =>
  function* (): Generator {
    const refreshToken = getRefreshTokenFromLocalStorage(tokenType)

    if (refreshToken) {
      yield delay(100) // needed prepare apollo client // TODO improve
      const tokenOk = yield call(renewToken(tokenType, onSetAuth))

      if (tokenOk) {
        yield call(onSuccess)
      } else {
        yield put(logoutAction)
      }
    }

    yield put(completeAction)

    yield spawn(checkTokenPeriodically(tokenType, logoutAction, onSetAuth))
  }

export default init
