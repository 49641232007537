import gql from 'graphql-tag'

const webhooksGetQuery = gql`
  query webhooksGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object, $nested: boolean) {
    webhooksGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, nested: $nested })
      @rest(type: "WebhooksResult", method: "POST", path: "eshop-webhook/list") {
      results
      paging
    }
  }
`

export default webhooksGetQuery
