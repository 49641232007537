import gql from 'graphql-tag'

const eshopGetQuery = gql`
  query eshopGet($id: String!) {
    eshopGet(id: $id) @rest(type: "Eshop", method: "GET", path: "eshop/{args.id}") {
      id
      name
      organisation
      defaultWarehouse
      warehouses
      active
      suppliers
    }
  }
`

export default eshopGetQuery
