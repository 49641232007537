import React from 'react'
import { Route } from 'react-router-dom'

type Props = {
  statusCode?: number
  children: React.ReactNode
}

const RouteStatus = ({ statusCode, children }: Props): JSX.Element => (
  <Route
    // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
    render={({ staticContext }) => {
      if (staticContext) {
        staticContext.statusCode = statusCode
      }
      return children
    }}
  />
)

export default RouteStatus
