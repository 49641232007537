import ReactDOM from 'react-dom/client'
import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { CaptureConsole, ExtraErrorData } from '@sentry/integrations'
import { Provider as ReduxProvider } from 'react-redux'
import { Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import App from './App'
import createStore from './store/create'
import createApolloClient from './utils/createApolloClient'
import getRuntimeConfig from './utils/getRuntimeConfig'
import history from './utils/history'

const dsn = getRuntimeConfig('FRONTEND__SENTRY_DSN')

if (dsn) {
  Sentry.init({
    dsn,
    environment: getRuntimeConfig('FRONTEND__SENTRY_ENV'),
    release: getRuntimeConfig('TAG_VERSION'),
    maxBreadcrumbs: 25,
    integrations: [new CaptureConsole({ levels: ['error'] }), new ExtraErrorData({ depth: 10 })],
    ignoreErrors: ['ResizeObserver loop limit exceeded', /Loading chunk [\d]+ failed/],
  })
} else {
  window.localStorage.debug = getRuntimeConfig('DEBUG')
}

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__
// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__

const store = createStore(preloadedState, history)
const client = createApolloClient(store)
store.setSagaContext({ apolloClient: client })
const persistor = persistStore(store)

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={client}>
          <ConnectedRouter history={history}>
            <Router history={history}>
              <App />
            </Router>
          </ConnectedRouter>
        </ApolloProvider>
      </PersistGate>
    </ReduxProvider>
  </React.StrictMode>,
)
