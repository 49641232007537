export type LastBatchSyncId = {
  product: string | null
  stock: string | null
  productHasOngoingSync: boolean
  stockHasOngoingSync: boolean
}

export enum BatchDetailStatus {
  Ongoing = 'ongoing',
  Completed = 'completed',
  Failed = 'failed',
}

export type BatchDetail = {
  id: string
  status: BatchDetailStatus
  mailship_warehouse_id?: string
  inventory_id?: string
  synced: number
  total: number
  failed: number
  errors?: string
  created_at?: string
  updated_at?: string
}
