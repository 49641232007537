import gql from 'graphql-tag'

const expeditionsSendAllToWmsQuery = gql`
  query expeditionsSendAllToWms($expeditions: [String]!) {
    expeditionsSendAllToWms(input: { expeditions: $expeditions })
      @rest(type: "ExpeditionsListResult", method: "PUT", path: "expedition/send") {
      results
    }
  }
`

export default expeditionsSendAllToWmsQuery
