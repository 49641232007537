import gql from 'graphql-tag'

const transfersGetQuery = gql`
  query transfersGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object) {
    transfersGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, select: $select, nested: true })
      @rest(type: "TransfersResult", method: "POST", path: "transfer/list") {
      results
      paging
    }
  }
`

export default transfersGetQuery
