import gql from 'graphql-tag'

const adminsGetQuery = gql`
  query adminsGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object) {
    adminsGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, nested: $nested })
      @rest(type: "Admins", method: "POST", path: "admin/list") {
      results
      paging
    }
  }
`

export default adminsGetQuery
