import gql from 'graphql-tag'
import { expeditionDetailFields } from './expeditionGetQuery'

const expeditionsSendToWmsQuery = gql`
  query expeditionsSendToWms($id: String!) {
    expeditionsSendToWms(input: {}, id: $id)
      @rest(type: "ExpeditionsListResult", method: "PUT", path: "expedition/{args.id}/send") {
        ${expeditionDetailFields}
    }
  }
`

export default expeditionsSendToWmsQuery
