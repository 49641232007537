import gql from 'graphql-tag'

const productDeleteQuery = gql`
  query productDelete($productId: String!, $imageId: String!) {
    productDelete(productId: $productId, imageId: $imageId)
      @rest(method: "DELETE", path: "product/{args.productId}/image/{args.imageId}") {
      id
    }
  }
`

export default productDeleteQuery
