import gql from 'graphql-tag'

const productBatchDeactivateQuery = gql`
  query productBatchDeactivate($products: [String]!) {
    productBatchDeactivate(input: { products: $products })
      @rest(type: "ProductBatchDeactivate", method: "PUT", path: "product/deactivate") {
      status
    }
  }
`

export default productBatchDeactivateQuery
