import { ApolloClient } from '@apollo/client'
import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import fetchAsyncExport from '@utils/fetchAsyncExport'
import productStocksGetQuery from '@queries/productStocksGetQuery'

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const criteria = {
    ...(action.payload?.criteria || {}),
    'product.type': { eq: 'physical' },
  }
  return client
    .query({ query: productStocksGetQuery, variables: { ...action.payload, criteria } })
    .then(({ data }) => ({ data: data?.productStocksGet?.results }))
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield takeLatest(actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
  yield takeLatest(actionTypes.export.run, createFetchSaga(actionTypes.export.run, fetchAsyncExport('product-stock')))
  yield takeLatest(actionTypes.loadList.run, createFetchSaga(actionTypes.loadList.run, fetchList))
}
