import gql from 'graphql-tag'

const stockAdviceQuery = gql`
  query stockAdvice(
    $user: String!
    $organisation: String!
    $warehouse: String!
    $wms: String
    $supplier: String!
    $packagingUnit: String!
    $countOfUnits: Int!
    $manualInput: Boolean
    $note: String
    $internalId: String
    $expectedAt: String
    $items: [String]!
    $ref1: String
    $ref2: String
    $ref3: String
  ) {
    stockAdvice(
      input: {
        user: $user
        organisation: $organisation
        warehouse: $warehouse
        wms: $wms
        supplier: $supplier
        packagingUnit: $packagingUnit
        countOfUnits: $countOfUnits
        manualInput: $manualInput
        note: $note
        internalId: $internalId
        expectedAt: $expectedAt
        items: $items
        ref1: $ref1
        ref2: $ref2
        ref3: $ref3
      }
    ) @rest(type: "StockAdviceResult", method: "POST", path: "stock-advice") {
      id
      user
      organisation
      warehouse
      wms
      supplier
      packagingUnit
      countOfUnits
      manualInput
      note
      internalId
      expectedAt
      ref1
      ref2
      ref3
    }
  }
`

export default stockAdviceQuery
