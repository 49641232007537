/**
 * Hotjar
 * @URL: https://hotjar.com/
 * -----------------------------------------------------------------------------------------------
 */
import getRuntimeConfig from '@utils/getRuntimeConfig'
import Hotjar from '@hotjar/browser'
import { take, call } from 'redux-saga/effects'
import { actionTypes } from '@store/user/index'
import { BareUser } from '@typings/entities/User'
import pick from 'lodash/pick'

const siteId = getRuntimeConfig('FRONTEND__ANALYTICS_HOTJAR_SITE_ID')
const hotjarVersion = 6

export const isAllowedHotjar = () => {
  const isAllowedAnalytics = localStorage?.allowedStatisticalCookies
  return isAllowedAnalytics && siteId
}

export function* initHotjar() {
  try {
    Hotjar.init(siteId, hotjarVersion, { debug: true })
    const { data: user } = (yield take(actionTypes.load.fetchSucceeded)) as { data: BareUser }
    yield call(identify, user)
  } catch (e) {}
}

function* identify(user: BareUser) {
  try {
    if (isAllowedHotjar() && Hotjar.isReady()) {
      Hotjar.identify(user.id, pick(user, ['firstName', 'lastName', 'sex']))
    }
  } catch (e) {
    console.error(e)
  }
}

function* onSetOrganisation() {
  try {
    if (isAllowedHotjar() && Hotjar.isReady()) {
      Hotjar.event('set organisation')
    }
  } catch (e) {
    console.error(e)
  }
}

function* onLocationChange({ payload }: Action) {
  try {
    if (isAllowedHotjar() && Hotjar.isReady()) {
      Hotjar.stateChange(payload?.location.pathname)
    }
  } catch (e) {
    console.error(e)
  }
}

export const events = {
  onSetOrganisation,
  onLocationChange,
}
