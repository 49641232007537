import { ApolloClient } from '@apollo/client'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import organisationsGetQuery from '@queries/organisationsGetQuery'
import { sagaDebounce } from '@store/helpers'

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({
      query: organisationsGetQuery,
      variables: {
        ...action.payload,
        select: [
          'id',
          'name',
          'owner',
          'addressLine1',
          'addressLine2',
          'addressLine3',
          'code',
          'primaryCountry',
          'ignoreAddressValidation',
          'active',
          'stockAdviceCloseInterval',
          'vatNumber',
          'registrationNumber',
          'strictFifo',
          'automaticExchangeEnabled',
        ],
        nested: true,
      },
    })
    .then(({ data }) => ({ data: data?.organisationsGet }))
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return // dont run on SSR
  yield sagaDebounce(200, actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
}
