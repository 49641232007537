import { takeLatest, select, delay, put, call, fork, getContext } from 'redux-saga/effects'
import { ApolloClient } from '@apollo/client'
import { selectors as uiSelectors, actions as uiActions, actionTypes as uiActionTypes } from './index'
import { selectors as organisationSelectors, actionTypes as organisationActionTypes } from '../organisation/index'
import { selectors as userSelectors } from '@store/user'
import { actionTypes as expeditionActionsTypes } from '@store/expeditions'
import reportExpeditionGetQuery from '@queries/reportExpeditionGetQuery'
import complaintsGetQuery from '@queries/complaintsGetQuery'
import reportReservationsPartiallyDelivered from '@queries/reportReservationsPartiallyDelivered'
import { actions as userActions } from '@store/user'
import { actions as adminActions } from '@store/admin'
import { onInitAdminSuccess } from '@store/admin/sagas'
import { onInitUserSuccess } from '@store/user/sagas'
import { selectors as routerSelectors } from '@store/connectedRouter'
import initToken from '@store/ui/checkTokenSaga'
import { selectors as adminSelectors } from '@store/admin'
import notificationSaga from './notificationSaga'
import checkVersionPeriodically from './checkVersionSaga'
import internalStockChangesCountGetQuery from '@queries/internalStockChangesCountGetQuery'

interface loadNotificationsProps {
  accountId: string
  organisationId: string
  client: ApolloClient<any>
  isUser?: boolean
}

async function loadCounters({ accountId, organisationId, client, isUser }: loadNotificationsProps): Promise<any> {
  // count of expedition that are in incorrect state
  const expeditionsPromise = client.query({
    query: reportExpeditionGetQuery,
    variables: {
      select: ['count'],
      criteria: {
        status: { eq: 'incorrect' },
        organisation: { eq: organisationId },
      },
    },
  })

  // count of reservations on partially expedited expeditions
  const reservationsPromise = client.query({
    query: reportReservationsPartiallyDelivered,
    variables: {
      organisation: { eq: organisationId },
    },
  })

  const role = isUser ? 'reporter' : 'resolver'

  const complaintsPromiseReporter = client.query({
    query: complaintsGetQuery,
    variables: {
      filters: {
        status: ['waiting_for_reporter'],
        [role]: { id: accountId },
        organisationId: [organisationId],
      },
      pagination: { limit: 1, offset: 0 },
    },
  })

  const complaintsPromiseResolver = client.query({
    query: complaintsGetQuery,
    variables: {
      filters: {
        status: ['waiting_for_resolver'],
        [role]: { id: accountId },
        organisationId: [organisationId],
      },
      pagination: { limit: 1, offset: 0 },
    },
  })

  const internalStockChangesPromise = client.query({
    query: internalStockChangesCountGetQuery,
    variables: {
      criteria: {
        organisation: { eq: organisationId },
        status: { eq: 'new' },
      },
    },
  })

  const [
    expeditionsResponse,
    reservationsResponse,
    complaintsReporterResponse,
    complaintsResolverResponse,
    internalStockChangesResponse,
  ] = await Promise.all([
    expeditionsPromise,
    reservationsPromise,
    complaintsPromiseReporter,
    complaintsPromiseResolver,
    internalStockChangesPromise,
  ])

  const reservationsResults = reservationsResponse?.data?.reportReservationGet
  const reservationCount = reservationsResults?.count || 0
  const expeditionsResults = expeditionsResponse?.data?.reportExpeditionGet?.results
  const expeditionsCount = expeditionsResults?.[0]?.count || 0
  const waitingForReporterCount = complaintsReporterResponse?.data?.complaintsGet?.paging?.total || 0
  const waitingForResolverCount = complaintsResolverResponse?.data?.complaintsGet?.paging?.total || 0
  const internalStockChangesCount = internalStockChangesResponse?.data?.internalStockChangesCountGet?.count || 0
  const warningsCount = expeditionsCount + waitingForReporterCount + waitingForResolverCount + internalStockChangesCount

  return {
    reservationCount,
    expeditionsCount,
    waitingForReporterCount,
    waitingForResolverCount,
    warningsCount,
    internalStockChangesCount,
  }
}

function* loadUiBadges(): Generator {
  yield put(uiActions.setIncorrectExpeditions(null))
  yield put(uiActions.setReservationsOnExpedited(null))
  yield put(uiActions.setUnseenNotifications(null))

  const organisationId = (yield select(organisationSelectors.organisation)) as string
  const accountId = (yield select((state) => adminSelectors.getAuth(state)?.id || userSelectors.getAuth(state)?.id)) as string // using auth to prevent race condition - TODO refactor
  const client = (yield getContext('@apolloClient')) as ApolloClient<any>
  const path = (yield select(routerSelectors.pathnameSelector)) as string
  const isUser = /^\/user/.test(path)

  if (!organisationId) return

  try {
    const {
      expeditionsCount,
      reservationCount,
      waitingForReporterCount,
      waitingForResolverCount,
      warningsCount,
      internalStockChangesCount,
    }: any = yield call(() => loadCounters({ accountId, organisationId, client, isUser }))

    yield notificationSaga()

    yield put(uiActions.setIncorrectExpeditions(expeditionsCount))
    yield put(uiActions.setReservationsOnExpedited(reservationCount))
    yield put(uiActions.setWarningsCount(isUser ? warningsCount : warningsCount + reservationCount))
    yield put(
      uiActions.setComplaintWarnings({
        waitingForReporter: waitingForReporterCount,
        waitingForResolver: waitingForResolverCount,
      }),
    )
    yield put(uiActions.setInternalStockChanges(internalStockChangesCount))
  } catch (e: any) {
    const error = String(e?.response?.data?.message || e?.message || e)
    console.error(error)
  }
}

function* pushDialog(action: Action): Generator {
  const cornerDialog = yield select((state) => uiSelectors.default(state, 'cornerDialog'))
  if (cornerDialog) {
    if (!action?.payload?.notimeout) {
      yield delay(6000)
      yield put(uiActions.pushDialog(null))
    }
  }
}

function* updateUiPeriodically(): Generator {
  while (true) {
    yield delay(10000)
    yield notificationSaga()
  }
}

function* init(): Generator {
  const path = (yield select(routerSelectors.pathnameSelector)) as string
  const isUser = /^\/user/.test(path)
  const isAdmin = /^\/admin/.test(path)
  const isNobody = !isUser && !isAdmin

  if (!localStorage?.isCookieBannerHidden) {
    yield put(uiActions.setCookieBannerHidden(false))
  }

  if (isUser || isNobody) {
    yield fork(initToken('user', onInitUserSuccess, userActions.logout(), userActions.setInitComplete(), userActions.setAuth))
  }

  if (isAdmin) {
    yield fork(
      initToken('admin', onInitAdminSuccess, adminActions.logout(), adminActions.setInitComplete(), adminActions.setAuth),
    )
  }
}

export default function* watch(): Generator {
  yield fork(checkVersionPeriodically)
  yield fork(updateUiPeriodically)
  yield fork(init)
  yield takeLatest(organisationActionTypes.setOrganisation, loadUiBadges)
  yield takeLatest(expeditionActionsTypes.editExpedition.fetchSucceeded, loadUiBadges)
  yield takeLatest(uiActionTypes.pushDialog, pushDialog)
}
