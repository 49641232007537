import { call, delay, put, select, spawn, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '@store/transferImport'
import { createSuccessAction } from '@utils/store/createFetchSaga'
import getRuntimeConfig from '@utils/getRuntimeConfig'
import { selectors as eshopSelectors } from '@store/eshops'
import { Eshop } from '@typings/entities/Eshop'
import { selectors as organisationSelector } from '@store/organisations'
import { Organisation } from '@typings/entities/Organisation'
import fetchFileAuthorized from '@utils/fetchFileAuthorized'
import createAxiosRequest from '@utils/createAxiosRequest'
import { displayToast } from '@utils/toast'
import { t } from '@lingui/macro'
import * as Sentry from '@sentry/browser'
import { actions as uiActions } from '@store/ui'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import sumBy from 'lodash/sumBy'

const importFinishedStatus = 'finished'
const importCheckDelay = 5000
const importPullingTimeInMinutes = 30

type ImportGetResult = {
  errors: Record<string, Array<{ error: string; row: number }>> | []
  status: string
  imported: Record<string, string> | []
}

function* downloadImportTemplate(action: Action): Generator {
  const state = (yield select()) as State
  const apiBaseUrl = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')
  const orgIdString = action?.payload?.orgId || ''
  const eshopIdString = action?.payload?.eshopId || ''
  const url = `${apiBaseUrl}transfer/template.${action?.payload?.fileFormat}?organisation_id=${orgIdString}&eshop_id=${eshopIdString}`
  yield put(createSuccessAction(actionTypes.downloadImportTemplate.run, action, null))
  const eshops = eshopSelectors.eshops(state)
  const eshopName = eshops.eshopsData.find((eshop: Eshop): boolean => eshop.id === eshopIdString)?.name
  const organisations = organisationSelector.organisations(state)
  const organisationName = organisations.organisationsData.find(
    (organisation: Organisation): boolean => organisation.id === orgIdString,
  )?.name
  const fileName = eshopName || organisationName ? `template_${eshopName || organisationName}` : 'template'
  return fetchFileAuthorized(url, `${fileName}.${action?.payload?.fileFormat}`)
}

const checkImport = async (id: string) => {
  const getImportData = new Promise((resolve, reject) => {
    const apiBaseUrl = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')
    createAxiosRequest({
      method: 'GET',
      url: `${apiBaseUrl}transfer/import/${id}`,
    })
      .then((res) => resolve(res?.data))
      .catch((e) => {
        const message = e?.response?.data?.message || e?.message
        if (message) {
          displayToast({
            type: 'error',
            title: t({ id: 'cornerDialog.failed.title', message: 'Failed' }),
            text: `${t({ id: 'notification.import.failed', message: 'Import failed!' })} ${message}`,
          })
          Sentry.captureMessage(message)
          reject(message)
        }
      })
  })
  const res = await Promise.resolve(getImportData)
  return res && (JSON.parse(res as string) as ImportGetResult)
}

function* checkImportPeriodically(id?: string) {
  if (!id) return
  let isPending = true
  const endTime = Date.now() + importPullingTimeInMinutes * 60 * 1000

  while (isPending) {
    const now = Date.now()
    const data = (yield call(checkImport, id)) as ImportGetResult
    const errorCount = isArray(data?.errors) ? data?.errors?.length : sumBy(Object.values(data?.errors), 'length')
    const successCount = data?.imported ? Object.values(data?.imported).length : 0
    const isFinished = data?.status === importFinishedStatus
    const isSuccess = errorCount === 0 && isFinished
    const isFailed = errorCount > 0 && isFinished
    const intent = isSuccess ? 'success' : isFailed ? 'error' : 'info'

    let result: string[] = []
    if (isObject(data?.errors)) {
      for (const value of Object.values(data?.errors)) {
        for (const item of value) {
          result = [...result, `Row ${item?.row}: ${item?.error}`]
        }
      }
    }

    yield put(
      uiActions.pushProcessDialog({
        isLoading: !isFinished,
        intent,
        successCount,
        errorCount,
        result,
      }),
    )

    if (now > endTime || isSuccess || isFailed) {
      isPending = false
    }

    yield delay(importCheckDelay)
  }
}

const getImportResultId = async (file: File) => {
  const uploadPromise = new Promise((resolve, reject) => {
    const apiBaseUrl = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')
    const formData = new FormData()
    formData.append('file', file)
    createAxiosRequest({
      method: 'POST',
      url: `${apiBaseUrl}transfer/import`,
      data: formData,
    })
      .then((res) => {
        resolve(res?.data?.resultId)
      })
      .catch((e) => {
        const message = e?.response?.data?.message || e?.message
        if (message) {
          displayToast({
            type: 'error',
            title: t({ id: 'cornerDialog.failed.title', message: 'Failed' }),
            text: `${t({ id: 'notification.import.failed', message: 'Import failed!' })} ${message}`,
          })
          Sentry.captureMessage(message)
          reject(message)
        }
      })
  })

  return Promise.resolve(uploadPromise)
}
function* importTransfers(action: Action) {
  const id = (yield call(getImportResultId, action.payload.file)) as string
  yield spawn(checkImportPeriodically, id)
  yield put(
    createSuccessAction(actionTypes.importTransfers.run, action, {
      uploadPromise: new Promise((resolve) => resolve(true)),
      getProgress: () => 100,
    }),
  )
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return

  yield takeLatest(actionTypes.downloadImportTemplate.run, downloadImportTemplate)
  yield takeLatest(actionTypes.importTransfers.run, importTransfers)
}
