import { messages as messagesEn } from './en/messages'
import { messages as messagesCs } from './cs/messages'
import { messages as messagesRu } from './ru/messages'
import { messages as messagesSk } from './sk/messages'
import { messages as messagesEs } from './es/messages'
import { messages as messagesIt } from './it/messages'
import { messages as messagesEl } from './el/messages'
import { en, cs, es, it, ru, sk, el } from 'make-plural/plurals'

export const catalogs = {
  en: messagesEn,
  cs: messagesCs,
  ru: messagesRu,
  sk: messagesSk,
  es: messagesEs,
  it: messagesIt,
  el: messagesEl,
}
export const plurals = { en, cs, es, it, ru, sk, el }
