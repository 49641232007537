import { ApolloClient } from '@apollo/client'
import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import expeditionItemsGetQuery from '@queries/expeditionItemsGetQuery'

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const expeditionItems = await client
    .query({ query: expeditionItemsGetQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.expeditionItemsGet }))

  return expeditionItems
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield takeLatest(actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
}
