import gql from 'graphql-tag'

const productActivateQuery = gql`
  query productActivate($id: String!) {
    productActivate(id: $id, input: {}) @rest(type: "ProductActivate", method: "PUT", path: "product/activate/{args.id}") {
      status
    }
  }
`

export default productActivateQuery
