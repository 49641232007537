import { WarehouseAsNested } from './Warehouse'
import { WmsAsNested } from './Wms'
import { OrganisationAsNested } from '@typings/entities/Organisation'

// TODO fix ALL types here

export type BookStockAdvice = {
  id?: string
  expeditionItem: string
  quantity: number
  createdAt?: string
}

export type NestedStockAdvice = {
  id: string
  internalId: string
  wmsInternalId: string
  createdAt: string
  status: string
  supplier: string
}

export type StockAdviceItemBase = {
  id: string
  product: {
    id: string
    internalSku: string
    name: string
    productSku: string
    referenceNumbers: string[]
    active: boolean
  }
  blocked: number
  quantity: number
  suppliedQuantity: number
  position?: number
  bookStockAdvices?: BookStockAdvice[]
}

export type StockAdviceItem = StockAdviceItemBase & {
  stockAdvice: string
}

// TODO fix item types here, expecialy this one
export type StockAdviceItemApi = StockAdviceItemBase & {
  stockAdvice: NestedStockAdvice
}

export type StockAdviceDetailItem = Omit<StockAdviceItemApi, 'stockAdvice' | 'blocked'> & {
  bookedAdviceTotal: number
  bookedStockAdvices: BookStockAdvice[]
  bookAdviceTotal: number
  unitPurchasePriceCurrency: string
  unitPurchasePrice: number
  stockAdvice?: StockAdviceItemApi['stockAdvice']
}

export type StockAdviceApi = {
  id: string
  user: string
  organisation: string
  warehouse: string
  wms: string
  supplier: string
  packagingUnit: string
  countOfUnits: number
  status: string
  manualInput: boolean
  items: StockAdviceItemApi[]
  note?: string
  internalId: string
  wmsInternalId: string
  expectedAt: string
  createdAt: string
  ref1: string
  ref2: string
  ref3: string
  changedAt: string
}

export type StockAdviceSagaGet = Omit<StockAdviceApi, 'items'> & {
  items: StockAdviceItemApi[] // TODO FIX, original items type is wrong, for example it does not actually have product expanded until here
}

export const asNestedFields = [
  'changedAt',
  'countOfItems',
  'countOfSku',
  'countOfUnits',
  'createdAt',
  'expectedAt',
  'id',
  'internalId',
  'wmsInternalId',
  'packagingUnit',
  'status',
  'sumOfQuantity',
  'sumOfSuppliedQuantity',
]

export type AdviceType = {
  id: string
  name: string
  quantity: number
  suppliedQuantity?: number
  productSku?: string
  internalSku?: string
  referenceNumbers?: string
}

export type StockAdvice = StockAdviceApi

export type StockAdviceDetail = Omit<StockAdviceApi, 'supplier' | 'items'> & {
  supplier: {
    id: string
    name: string
  }
  items: StockAdviceDetailItem
}

export type InboundApiWrite = StockAdviceApi
