import { ApolloClient } from '@apollo/client'
import { takeLatest } from 'redux-saga/effects'
import getOr from 'lodash/fp/getOr'
import { t } from '@lingui/macro'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import complaintsGetQuery from '@queries/complaintsGetQuery'
import { expeditionsGetFiltersQuery } from '@queries/expeditionsGetQuery'
import toSelectOption from '@utils/toSelectOption'
import { displayToast } from '@utils/toast'
import { sagaDebounce } from '@store/helpers'
import fetchFileAuthorized from '@utils/fetchFileAuthorized'
import getRuntimeConfig from '@utils/getRuntimeConfig'
import buildFilters from '@utils/queries/buildFilters'

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const { sorting, criteria, limit, offset } = action.payload
  const variables = buildFilters(criteria, sorting, limit, offset)
  const resolutions = getOr([], 'filters.resolution', variables)

  if (resolutions.includes('no_resolution')) {
    variables.filters.resolution = []
    if (resolutions.length > 1) {
      displayToast({
        type: 'info',
        text: t({
          id: 'complaints.filters.noResolutionSelected',
          message: '"No resolution" filter selected. Other selected resolution states will be ingored.',
        }),
      })
    }
  }

  const complaints = await client
    .query({ query: complaintsGetQuery, variables })
    .then(({ data }) => ({ data: data?.complaintsGet }))

  return complaints
}

const fetchFilters = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const organisationId = action.payload
  const expeditionsFilters = await client
    .query({ query: expeditionsGetFiltersQuery, variables: { path: organisationId ? `/${organisationId}` : '' } })
    .then(({ data }) => data?.expeditionsGetFilters)

  return {
    data: {
      deliveryCountry: expeditionsFilters.countries.map(toSelectOption('code', 'name')),
      carrier: expeditionsFilters.carriers.map(toSelectOption('id', 'name')),
      carrierServices: expeditionsFilters.carrierServices.map(toSelectOption('id', 'name')),
    },
  }
}

const exportComplaints = (client: ApolloClient<any>, action: Action) => {
  const { criteria, format } = action.payload
  const variables = buildFilters(criteria)
  const baseURL = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL_V3')
  const body = JSON.stringify(variables)
  fetchFileAuthorized(`${baseURL}fe-complaints/export/${format}`, `export.${format}`, 'POST', body)
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield sagaDebounce(200, actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
  yield takeLatest(actionTypes.loadAsyncFilters.run, createFetchSaga(actionTypes.loadAsyncFilters.run, fetchFilters))
  yield takeLatest(actionTypes.export.run, createFetchSaga(actionTypes.export.run, exportComplaints))
}
