// import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject, ApolloLink } from 'apollo-boost'
import { ApolloClient, NormalizedCacheObject, ApolloLink } from '@apollo/client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { RestLink } from 'apollo-link-rest'
import { onError } from 'apollo-link-error'
import getRuntimeConfig from '@utils/getRuntimeConfig'
import getAuthorizationToken from '@utils/getAuthorizationToken'
import { getLanguage } from '@utils/language'
import * as Sentry from '@sentry/browser'

const noTokenEndpoints = ['/refresh-token', '/login/admin', '/login/user']
const uri = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')
const endpoints = { gateway: getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL_V3') }
const ignorredOperations = ['reservationUnbook', 'reservationMove'] // This operations has custom error handling for status code 409

const isUserRequest = (request: string) => request === '/api/me' || /^\/user/.test(window.location.pathname)

const createLink = (store: any): ApolloLink => {
  const httpLink = new RestLink({
    endpoints,
    uri,
    customFetch: (request, params: RequestInit): Promise<Response> => {
      const tokenType = isUserRequest(String(request)) ? 'user' : 'admin'
      const token = getAuthorizationToken(tokenType, store)
      const uiLanguage = getLanguage()

      if (params.headers instanceof Headers) {
        if (uiLanguage) {
          params.headers?.append('Accept-Language', uiLanguage)
        }

        if (token && !noTokenEndpoints.find((endpoint) => String(request).includes(endpoint))) {
          params.headers?.append('Authorization', `Bearer ${token}`)
        }
      }

      return fetch(request, params)
    },
  })

  const errorLink = onError((err) => {
    const suppressError = err?.operation?.getContext()?.suppressError

    if (suppressError && suppressError(err?.networkError?.result, err?.networkError?.statusCode)) {
      return
    }

    Sentry.withScope((scope) => {
      const context = { variables: err?.operation?.variables || '', query: err?.operation?.query?.loc?.source?.body || '' }
      const graphqlError = err.graphQLErrors
      const restError = err.networkError?.result?.error || err.networkError?.result?.message || err.networkError?.message
      const error = graphqlError || restError
      scope.setExtra('context', context)

      // not report of unauthorized requests
      if (err?.networkError?.statusCode === 401) {
        return
      }

      if (error) {
        Sentry.captureMessage(`API Error: ${error}`)
      } else {
        Sentry.captureException(err)
      }
    })

    if (err.graphQLErrors) {
      err.graphQLErrors.forEach((error) => {
        const { message, locations, path } = error
        store.dispatch({ type: '%%apollo%%ERROR', payload: { message, locations, path, type: 'graphQLError' } })
      })
    }

    if (err.networkError) {
      if (err?.networkError?.statusCode === 409 && ignorredOperations.includes(err.operation.operationName)) {
        return
      }

      const message = err.networkError.result?.error || err.networkError.result?.message || err.networkError.message
      const violations = err.networkError.result?.violations || []
      const violationsMessage = violations?.map((violation) => violation?.message)?.join(',') || ''
      const extendedMessage = `${message} ${violationsMessage}`
      store.dispatch({ type: '%%apollo%%ERROR', payload: { error: extendedMessage, type: 'networkError' } })
    }
  })

  const link = errorLink.concat(httpLink)

  return link
}

export default function createClient(store: any): ApolloClient<NormalizedCacheObject> {
  const client = new ApolloClient({
    connectToDevTools: true,
    ssrMode: false,
    link: createLink(store),
    cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  })

  return client
}
