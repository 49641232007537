import gql from 'graphql-tag'

const productDeactivateQuery = gql`
  query productDeactivate($id: String!) {
    productDeactivate(id: $id, input: {}) @rest(type: "ProductDeactivate", method: "PUT", path: "product/deactivate/{args.id}") {
      status
    }
  }
`

export default productDeactivateQuery
