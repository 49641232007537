import gql from 'graphql-tag'

const productBatchDeleteQuery = gql`
  query productBatchDelete($products: [String]!) {
    productBatchDelete(input: { products: $products }) @rest(type: "ProductBatchDelete", method: "PUT", path: "product/delete") {
      status
    }
  }
`

export default productBatchDeleteQuery
