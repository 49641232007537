import gql from 'graphql-tag'

const refreshTokenQuery = gql`
  query refreshToken($refreshToken: String!) {
    newToken(input: { refreshToken: $refreshToken })
      @rest(type: "NewToken", method: "POST", path: "refresh-token", endpoint: "gateway") {
      token
      exp
    }
  }
`

export default refreshTokenQuery
