import gql from 'graphql-tag'
import { expeditionDetailFields } from './expeditionGetQuery'

const expeditionsProcessToWmsQuery = gql`
  query expeditionsProcessToWms($id: String!) {
    expeditionsProcessToWms(input: {}, id: $id)
      @rest(type: "ExpeditionsListResult", method: "PUT", path: "expedition/{args.id}/process") {
        ${expeditionDetailFields}
    }
  }
`

export default expeditionsProcessToWmsQuery
