import gql from 'graphql-tag'

const stockChangesGetQuery = gql`
  query stockChangesGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object) {
    stockChangesGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, nested: true })
      @rest(type: "StockChangesResult", method: "POST", path: "stock-change/list") {
      results
      paging
    }
  }
`

export default stockChangesGetQuery
