import gql from 'graphql-tag'

const RemoveUsersFromOrganisationQuery = gql`
  query organisationRemoveUsersToOrganisation($organisationId: number, $users: Object) {
    organisationRemoveUsersToOrganisation(organisationId: $organisationId, input: { users: $users })
      @rest(type: "RemoveUsersToOrganisationResult", method: "PUT", path: "organisation/{args.organisationId}/user-remove") {
      data
    }
  }
`

export default RemoveUsersFromOrganisationQuery
