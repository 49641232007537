import gql from 'graphql-tag'

const stockAdviceDeleteQuery = gql`
  query stockAdviceDelete($id: String!) {
    stockAdviceDelete(id: $id) @rest(type: "StockAdviceResult", method: "DELETE", path: "stock-advice/{args.id}") {
      result
    }
  }
`

export default stockAdviceDeleteQuery
