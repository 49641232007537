import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import { BaselinkerApi } from '@services/BaselinkerApi'

const fetchList = async (_: any, { payload }: Action): Promise<any> => {
  const {
    criteria: { updatedAt, syncDetailToken, ...rest },
    limit,
    offset,
  } = payload

  return BaselinkerApi(syncDetailToken)
    .product.productSyncAggregated({
      limit,
      offset,
      from: updatedAt?.gte,
      to: updatedAt?.lt,
      ...rest,
    })
    .catch((e: any) => {
      const error = String(e?.response?.data?.message || e?.message || e)
      console.error(error)
    })
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield takeLatest(actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
}
