import gql from 'graphql-tag'

const stockAdviceSendQuery = gql`
  query stockAdviceSendQuery($id: String!, $status: String!) {
    stockAdviceSendQuery(id: $id, status: $status, input: {})
      @rest(method: "PUT", path: "stock-advice/status/{args.id}/{args.status}") {
      id
      user
      organisation
      warehouse
      wms
      supplier
      packagingUnit
      countOfUnits
      createdAt
      status
      manualInput
      items
      note
      internalId
      wmsInternalId
      expectedAt
      createdAt
      ref1
      ref2
      ref3
      changedAt
    }
  }
`

export default stockAdviceSendQuery
