import { select, put, call, getContext } from 'redux-saga/effects'
import { ApolloClient } from '@apollo/client'
import { selectors as organisationSelectors } from '../organisation/index'
import { selectors as organisationsSelectors } from '@store/organisations'
import { selectors as userSelectors } from '@store/user'
import notificationsUnseenCountGet from '@queries/notificationUnseenCountGet'
import { actions as uiActions } from './index'
import { isTokenExpired } from '@utils/getAuthorizationToken'
import { OrganisationApi } from '@typings/entities/Organisation'

const notificationQuery = {
  query: notificationsUnseenCountGet,
  context: { suppressError: () => true },
}

function* notificationSaga(): Generator {
  try {
    const client: ApolloClient<any> | undefined = (yield getContext('@apolloClient')) as ApolloClient<any>
    const organisation = (yield select(organisationSelectors.organisation)) as string
    const organisations = (yield select(organisationsSelectors.organisations)) as { organisationsData: OrganisationApi[] }
    const allOrganisationsId: string[] = (organisations?.organisationsData || []).map(({ id }) => id)
    const hasSelectedOrganisation = allOrganisationsId.includes(organisation)
    const userData: any = yield select(userSelectors.user)
    const isUserLoggedIn = !!userData?.userData?.id

    if (!organisation || !isUserLoggedIn || !client || !hasSelectedOrganisation || isTokenExpired('user')) {
      return
    }

    const fetchNotification = () => client?.query({ ...notificationQuery, variables: { id: organisation } })
    const notificationsResponse = (yield call(fetchNotification)) as { data: { notificationsUnseenCountGet: number } }
    const notificationsCount = notificationsResponse?.data?.notificationsUnseenCountGet || 0
    yield put(uiActions.setUnseenNotifications(notificationsCount))
  } catch (e) {
    yield put(uiActions.setUnseenNotifications(0))
  }
}

export default notificationSaga
