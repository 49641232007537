import gql from 'graphql-tag'

const eshopCreateQuery = gql`
  query eshopCreate($name: String!, $organisationId: Int, $defaultWarehouseId: String) {
    eshopCreate(input: { name: $name, organisation: $organisationId, defaultWarehouse: $defaultWarehouseId, active: 1 })
      @rest(type: "EshopCreateResult", method: "POST", path: "eshop") {
      result
    }
  }
`

export default eshopCreateQuery
