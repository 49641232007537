import gql from 'graphql-tag'

const returnReceiptsGetQuery = gql`
  query returnReceiptsGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object) {
    returnReceiptsGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, nested: true })
      @rest(type: "ReturnReceiptsListResult", method: "POST", path: "return-receipt/list") {
      results
      paging
    }
  }
`

export default returnReceiptsGetQuery
