import { History } from 'history'
import pick from 'lodash/pick'
import { Middleware } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import * as Sentry from '@sentry/browser'
import createSentryMiddleware from 'redux-sentry-middleware'

const createMiddlewares = (history: History): Middleware[] => [
  routerMiddleware(history),
  createSentryMiddleware(Sentry, {
    stateTransformer: (state: object) => pick(state, ['user', 'ui']),
  }),
]

export default createMiddlewares
