import { ApolloClient } from '@apollo/client'
import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import webhooksGetQuery from '@queries/webhooksGetQuery'

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({ query: webhooksGetQuery, variables: { ...action.payload, nested: true } })
    .then(({ data }) => ({ data: data?.webhooksGet }))
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return // dont run on SSR
  yield takeLatest(actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
}
