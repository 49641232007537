import { ApolloClient } from '@apollo/client'
import createFetchSaga from '@utils/store/createFetchSaga'
import { actionTypes as organisationActionTypes } from '@store/organisation'
import { actionTypes } from './index'
import { takeLatest } from 'redux-saga/effects'
import { stockMovementsGetFiltersQuery } from '@queries/stockMovementsGetQuery'
import toSelectOption from '@utils/toSelectOption'

const fetchWarehouses = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const { data } = await client.query({
    query: stockMovementsGetFiltersQuery,
    variables: {
      path: action.payload ? `/${action.payload}` : '',
    },
  })

  return {
    data: {
      warehouses: data.stockMovementsGetFilters.warehouses.map(toSelectOption('id', 'name')),
      wms: data.stockMovementsGetFilters.wmses.map(toSelectOption('id', 'name')),
    },
  }
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield takeLatest(organisationActionTypes.setOrganisation, createFetchSaga(actionTypes.run, fetchWarehouses))
}
