import gql from 'graphql-tag'

/**
 * Open already CLOSED stock advice
 */
const stockAdviceOpenQuery = gql`
  query stockAdviceOpen($id: String!) {
    stockAdviceOpen(id: $id, input: {}) @rest(method: "PUT", path: "stock-advice/open/{args.id}") {
      id
      user
      organisation
      warehouse
      wms
      supplier
      packagingUnit
      countOfUnits
      status
      manualInput
      items
      note
      internalId
      wmsInternalId
      expectedAt
      createdAt
      ref1
      ref2
      ref3
      changedAt
    }
  }
`

export default stockAdviceOpenQuery
