import gql from 'graphql-tag'
import { expeditionDetailFields } from './expeditionGetQuery'

const expeditionRollbackQuery = gql`
  query expeditionRollback($id: String!) {
    expeditionRollback(id: $id, input: {}) @rest(type: "Expedition", method: "PUT", path: "expedition/{args.id}/rollback") {
      ${expeditionDetailFields}
    }
  }
`

export default expeditionRollbackQuery
