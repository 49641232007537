import gql from 'graphql-tag'

const notificationsUnseenCountGet = gql`
  query notificationsUnseenCountGet($id: String!) {
    notificationsUnseenCountGet(id: $id)
      @rest(type: "NotificationCount", method: "GET", path: "notification-count/{args.id}/unseen", endpoint: "gateway")
    result
  }
`

export default notificationsUnseenCountGet
