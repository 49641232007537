import gql from 'graphql-tag'

const internalStockChangesCountGetQuery = gql`
  query internalStockChangesCountGet($criteria: Object) {
    internalStockChangesCountGet(input: { criteria: $criteria })
      @rest(type: "InternalStockChangesCountResult", method: "POST", path: "internal-stock-change/list/count") {
      count
    }
  }
`

export default internalStockChangesCountGetQuery
