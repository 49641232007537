import gql from 'graphql-tag'

const eshopBaselinkerGet = gql`
  query eshopBaselinkerGet($id: String!) {
    eshopBaselinkerGet(id: $id) @rest(type: "BaselinkerGetResult", method: "GET", path: "baselinker/eshop-setting/{args.id}") {
      token
      syncDetailToken
      warehouseMapping
      unsynchronizedOrderStatusMapping
      expeditionStatusMapping
      carrierMapping
    }
  }
`

export default eshopBaselinkerGet
