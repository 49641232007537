import { ApolloClient } from '@apollo/client'
import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import fetchAsyncExport from '@utils/fetchAsyncExport'
import toSelectOption from '@utils/toSelectOption'
import expeditionsGetQuery, { expeditionsGetFiltersQuery } from '@queries/expeditionsGetQuery'
import { sagaDebounce } from '@store/helpers'

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const expeditions = await client
    .query({ query: expeditionsGetQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.expeditionsGet }))

  return expeditions
}

const fetchFilters = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const { orgId, eshopId } = action.payload
  const expeditionsFilters = await client
    .query({
      query: expeditionsGetFiltersQuery,
      variables: {
        path: orgId ? `/${orgId}${eshopId ? `/${eshopId}` : ''}` : '',
      },
    })
    .then(({ data }) => data?.expeditionsGetFilters)

  return {
    data: {
      warehouse: expeditionsFilters.warehouses.map(toSelectOption('id', 'name')),
      wms: expeditionsFilters.wmses.map(toSelectOption('id', 'name')),
      deliveryCountry: expeditionsFilters.countries.map(toSelectOption('code', 'name')),
      carrier: expeditionsFilters.carriers.map(toSelectOption('id', 'name')),
      carrierServices: expeditionsFilters.carrierServices.map(toSelectOption('id', 'name')),
    },
  }
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield sagaDebounce(200, actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
  yield takeLatest(actionTypes.loadAsyncFilters.run, createFetchSaga(actionTypes.loadAsyncFilters.run, fetchFilters))
  yield takeLatest(
    actionTypes.exportExpeditions.run,
    createFetchSaga(actionTypes.exportExpeditions.run, fetchAsyncExport('expedition')),
  )
}
