import gql from 'graphql-tag'

const productBatchActivateQuery = gql`
  query productBatchActivate($products: [String]!) {
    productBatchActivate(input: { products: $products })
      @rest(type: "ProductBatchActivate", method: "PUT", path: "product/activate") {
      status
    }
  }
`

export default productBatchActivateQuery
