import { call, getContext, put, takeEvery } from 'redux-saga/effects'
import { createFailedAction, createStartedAction, createSuccessAction } from '@utils/store/createFetchSaga'
import { ApolloClient } from '@apollo/client'
import { actionTypes } from './index'

function* fetchData(action: Action) {
  const client: ApolloClient<any> = yield getContext('@apolloClient')
  yield put(createStartedAction(action.type))

  try {
    const response = (yield call(action.payload.query, client, action)) as number
    yield put(createSuccessAction(action.type, action, response))
    action.payload.setData(response)
  } catch (e) {
    yield put(createFailedAction(action.type, action, e))
    action.payload.setError(e)
  }

  action.payload.setIsLoading(false)
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return

  yield takeEvery(actionTypes.loadWidgetData.run, fetchData)
}
