import gql from 'graphql-tag'

const inboundUpdateQuery = gql`
  query inboundUpdate(
    $id: String!
    $warehouse: String
    $supplier: String
    $packagingUnit: String
    $countOfUnits: Int
    $note: String
    $internalId: String
    $expectedAt: String
    $changedAt: String
    $createdAt: String
    $items: [String]!
    $ref1: String
    $ref2: String
    $ref3: String
  ) {
    inboundUpdate(
      id: $id
      input: {
        warehouse: $warehouse
        supplier: $supplier
        packagingUnit: $packagingUnit
        countOfUnits: $countOfUnits
        internalId: $internalId
        expectedAt: $expectedAt
        changedAt: $changedAt
        createdAt: $createdAt
        items: $items
        note: $note
        ref1: $ref1
        ref2: $ref2
        ref3: $ref3
      }
    ) @rest(type: "InboundUpdateResult", method: "PUT", path: "stock-advice/{args.id}") {
      id
      user
      organisation
      warehouse
      wms
      supplier
      packagingUnit
      countOfUnits
      createdAt
      status
      manualInput
      items
      note
      internalId
      wmsInternalId
      expectedAt
      createdAt
      ref1
      ref2
      ref3
      changedAt
    }
  }
`

export default inboundUpdateQuery
