import { ApolloClient } from '@apollo/client'
import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import transfersGetQuery from '@queries/transfersGetQuery'
import fetchAsyncExport from '@utils/fetchAsyncExport'
import { sagaDebounce } from '@store/helpers'

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return await client
    .query({ query: transfersGetQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.transfersGet }))
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield sagaDebounce(200, actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
  yield takeLatest(actionTypes.export.run, createFetchSaga(actionTypes.export.run, fetchAsyncExport('transfer')))
}
