import { ApolloClient } from '@apollo/client'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import inboundReceiptsGetQuery from '@queries/inboundReceiptsGetQuery'
import { InboundReceiptApi } from '@typings/entities/InboundReceipt'
import stockAdvicesGetQuery from '@queries/stockAdvicesGetQuery'
import { sagaDebounce } from '@store/helpers'

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const inboundReceipts = await client
    .query({ query: inboundReceiptsGetQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.inboundReceiptsGet }))

  // reason for this subrequest is the supplier column alone
  const stockAdviceIds = (inboundReceipts?.data?.results || []).map(
    (inboundReceipt: InboundReceiptApi) => inboundReceipt.stockAdvice,
  )
  if (stockAdviceIds.length < 1) return inboundReceipts
  const stockAdvices = await client
    .query({
      query: stockAdvicesGetQuery,
      variables: {
        criteria: { id: { in: stockAdviceIds } },
        nested: true,
      },
    })
    .then(({ data }) => data?.stockAdvicesGet?.results)
  inboundReceipts.data.results = (inboundReceipts?.data?.results || []).map((inboundReceipt: InboundReceiptApi) => ({
    ...inboundReceipt,
    stockAdvice:
      inboundReceipt.stockAdvice && stockAdvices.find((stockAdvice: any) => inboundReceipt.stockAdvice === stockAdvice.id),
  }))

  return inboundReceipts
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield sagaDebounce(200, actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
}
