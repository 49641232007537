import { ApolloClient } from '@apollo/client'
import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import eshopsGetQuery from '@queries/eshopsGetQuery'
import eshopGetQuery from '@queries/eshopGetQuery'
import eshopCreateQuery from '@queries/eshopCreateQuery'
import eshopUpdateQuery from '@queries/eshopUpdateQuery'
import eshopDeleteQuery from '@queries/eshopDeleteQuery'
import byznysUserCreate from '@queries/byznysUserCreate'

// todo delete
const fetchListObsolete = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({ query: eshopsGetQuery, variables: { ...action.payload, nested: true } })
    .then(({ data }) => ({ data: data?.eshopsGet?.results }))
}

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({ query: eshopsGetQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.eshopsGet?.results }))
}

const fetchDetail = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: eshopGetQuery, variables: action.payload }).then(({ data }) => ({ data: data?.eshopGet }))
}

const fetchCreate = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: eshopCreateQuery, variables: action.payload })
}

const fetchUpdate = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const eshop = await client
    .query({ query: eshopGetQuery, variables: { id: action.payload?.id } })
    .then(({ data }) => data?.eshopGet)
  return client.query({ query: eshopUpdateQuery, variables: { ...eshop, ...action.payload } })
}

const fetchDelete = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: eshopDeleteQuery, variables: action.payload })
}

const fetchCreateByznysUser = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: byznysUserCreate, variables: action.payload })
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return // dont run on SSR
  yield takeLatest(actionTypes.run, createFetchSaga(actionTypes.run, fetchListObsolete))
  yield takeLatest(actionTypes.loadDetail.run, createFetchSaga(actionTypes.loadDetail.run, fetchDetail))
  yield takeLatest(actionTypes.loadList.run, createFetchSaga(actionTypes.loadList.run, fetchList))
  yield takeLatest(actionTypes.create.run, createFetchSaga(actionTypes.create.run, fetchCreate))
  yield takeLatest(actionTypes.update.run, createFetchSaga(actionTypes.update.run, fetchUpdate))
  yield takeLatest(actionTypes.delete.run, createFetchSaga(actionTypes.delete.run, fetchDelete))
  yield takeLatest(actionTypes.createByznysUser.run, createFetchSaga(actionTypes.createByznysUser.run, fetchCreateByznysUser))
}
