import { ApolloClient } from '@apollo/client'
import stockMovementsGetQuery, { stockMovementsGetFiltersQuery } from '@queries/stockMovementsGetQuery'
import inboundReceiptsGetQuery from '@queries/inboundReceiptsGetQuery'
import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from '@store/stockMovementsGrid'
import createFetchSaga from '@utils/store/createFetchSaga'
import fetchAsyncExport from '@utils/fetchAsyncExport'
import toSelectOption from '@utils/toSelectOption'
import { StockMovement } from '@typings/entities/StockMovement'
import { InboundReceipt } from '@typings/entities/InboundReceipt'
import get from 'lodash/get'
import { sagaDebounce } from '@store/helpers'

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const stockMovements = await client
    .query({ query: stockMovementsGetQuery, variables: action.payload })
    .then(({ data }) => data?.stockMovementsGet)

  const stockMovementsInboundReceiptIds = stockMovements.results
    .filter((stockMovement: StockMovement): boolean => !!stockMovement.inboundReceipt)
    .map((stockMovement: StockMovement): string | undefined => stockMovement.inboundReceipt?.id)

  if (stockMovementsInboundReceiptIds.length < 1) {
    return {
      data: { ...stockMovements },
    }
  }

  const inboundReceipts = await client
    .query({
      query: inboundReceiptsGetQuery,
      variables: {
        nested: true,
        criteria: {
          id: {
            in: stockMovementsInboundReceiptIds,
          },
        },
      },
    })
    .then(({ data }) => data?.inboundReceiptsGet)

  return {
    data: {
      ...stockMovements,
      results: stockMovements.results.map((stockMovement: StockMovement) => ({
        ...stockMovement,
        stockAdvice: get(
          inboundReceipts.results.find(
            (inboundReceipt: InboundReceipt) => inboundReceipt.id === stockMovement.inboundReceipt?.id,
          ),
          'stockAdvice',
        ),
      })),
    },
  }
}

const fetchFilters = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const stockMovementsFilters = await client
    .query({
      query: stockMovementsGetFiltersQuery,
      variables: {
        path: action.payload.orgId ? `/${action.payload.orgId}` : '',
      },
    })
    .then(({ data }) => data?.stockMovementsGetFilters)

  return {
    data: {
      warehouse: stockMovementsFilters.warehouses.map(toSelectOption('id', 'name')),
      wms: stockMovementsFilters.wmses.map(toSelectOption('id', 'name')),
    },
  }
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield sagaDebounce(200, actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
  yield takeLatest(actionTypes.stockMovementFilters.run, createFetchSaga(actionTypes.stockMovementFilters.run, fetchFilters))
  yield takeLatest(actionTypes.export.run, createFetchSaga(actionTypes.export.run, fetchAsyncExport('stock-movement')))
}
