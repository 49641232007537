import { takeLatest, fork, call } from 'redux-saga/effects'
import { actionTypes as organisationActionTypes } from '@store/organisation'
import { initMixPanel, isAllowedMixpanel, events as eventsMixpanel } from './mixpanel'
import { initHotjar, isAllowedHotjar, events as eventsHotjar } from './hotjar'
import { actionTypes } from './'
import { regexEditSuccess, regexCreateSuccess } from './utils'

function* initAnalytics(): Generator {
  if (isAllowedMixpanel()) {
    yield fork(initMixPanel)
  }

  if (isAllowedHotjar()) {
    yield fork(initHotjar)
  }
}

function* allowStatisticalCookies(): Generator {
  window.localStorage.setItem('allowedStatisticalCookies', 'true')
  yield call(initAnalytics)
}

export default function* watch(): Generator {
  yield fork(initAnalytics)
  yield takeLatest(actionTypes.allowedStatisticalCookies, allowStatisticalCookies)
  yield takeLatest(organisationActionTypes.setOrganisation, eventsHotjar.onSetOrganisation)

  yield takeLatest((action: Action) => regexEditSuccess.test(action.type), eventsMixpanel.onEditAction)
  yield takeLatest((action: Action) => regexCreateSuccess.test(action.type), eventsMixpanel.onCreateAction)

  yield takeLatest('@@router/LOCATION_CHANGE', eventsMixpanel.onLocationChange)
  yield takeLatest('@store/CommonGrid/addFilter', eventsMixpanel.onCommonGridAddFilter)
  yield takeLatest('@store/CommonGrid/setColumnConfig', eventsMixpanel.onCommonGridSetColumnConfig)
  yield takeLatest(organisationActionTypes.setOrganisation, eventsMixpanel.onSetOrganisation)
}
