import { ApolloClient } from '@apollo/client'
import expeditionGetQuery from '@queries/expeditionGetQuery'
import expeditionCreateQuery from '@queries/expeditionCreateQuery'
import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import expeditionCancelQuery from '@queries/expeditionCancelQuery'
import expeditionRollbackQuery from '@queries/expeditionRollbackQuery'
import expeditionUpdateQuery from '@queries/expeditionUpdateQuery'
import expeditionsGetQuery from '@queries/expeditionsGetQuery'
import productsGetQuery from '@queries/productsGetQuery'
import eshopsGetQuery from '@queries/eshopsGetQuery'
import fileSimpleDownload from '@queries/fileSimpleDownload'
import expeditionsProcessToWmsQuery from '@queries/expeditionsProcessToWmsQuery'
import expeditionsSendAllToWmsQuery from '@queries/expeditionsSendAllToWmsQuery'
import { ProductApiRead } from '@typings/entities/Product'
import { DeletedItemApiRead, ExpeditionApi, ExpeditionItemApiRead, ExpeditionNested } from '@typings/entities/Expedition'
import expeditionInvoiceDeleteQuery from '@queries/expeditionInvoiceDeleteQuery'
import { fileFormUpload } from '@queries/multipartFormDataUpload'
import expeditionsSendToWmsQuery from '@queries/expeditionsSendToWmsQuery'
import getRuntimeConfig from '@utils/getRuntimeConfig'

const apiBaseUrl = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({ query: expeditionsGetQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.expeditionsGet }))
}

const fetchExpedition = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const result = await client
    .query({ query: expeditionGetQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.expeditionGet }))

  // fetch products & eshop

  const eshopId = result.data.eshop || null
  const eshopPromise = eshopId
    ? client
        .query({
          query: eshopsGetQuery,
          variables: {
            criteria: { id: eshopId },
          },
        })
        .then(({ data }) => data?.eshopsGet?.results)
    : Promise.resolve(null)

  const productIds = [
    ...(result.data.items || []).map((item: ExpeditionItemApiRead) => item.product),
    ...(result.data.removedVirtualProducts || []).map((item: DeletedItemApiRead) => item.productId),
  ]
  const productsPromise = productIds.length
    ? client
        .query({
          query: productsGetQuery,
          variables: {
            select: ['id', 'productSku', 'internalSku', 'name', 'referenceNumbers', 'workAroundLot', 'eshops', 'active', 'type'],
            criteria: {
              id: { in: productIds },
            },
            nested: true,
            limit: 1000,
          },
        })
        .then(({ data }) => data?.productsGet?.results)
    : Promise.resolve([])

  const [eshop, products] = await Promise.all([eshopPromise, productsPromise])

  // set product & eshop

  result.data.eshopObj = eshop?.[0] // todo this could now probably be carefully refactored out
  if (productIds) {
    result.data.items = result.data.items.map((item: ExpeditionItemApiRead) => {
      const product = products.find((product: ProductApiRead) => product.id === item.product) || {}
      return {
        ...item,
        product: product,
      }
    })
    result.data.removedVirtualProducts = (result.data.removedVirtualProducts || []).map((item: DeletedItemApiRead) => {
      const product = products.find((product: ProductApiRead) => product.id === item.productId) || {}
      return {
        ...item,
        product: product,
      }
    })
  }

  return result
}

const fetchDownloadInvoice = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return fileSimpleDownload(`${apiBaseUrl}expedition/${action.payload}/invoice`, 'invoice.pdf')
}

const fetchCreateExpedition = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const hasCarrierOptions = action.payload.carrierOptions
  const invoiceFile = action.payload?.deliveryPdfFile
  const originalExpeditionId = action.payload?.originalId

  let carrierOptions = hasCarrierOptions ? { ...action.payload.carrierOptions } : undefined

  if (hasCarrierOptions && action.payload.carrierOptions.recipientIdentificationNumber === '') {
    carrierOptions = {
      ...action.payload.carrierOptions,
      recipientIdentificationNumber: null,
    }
  }

  const result: { data: ExpeditionApi } = await client
    .query({ query: expeditionCreateQuery, variables: { ...action.payload, carrierOptions } })
    .then(({ data }) => ({ data: data?.expeditionCreate }))

  if (invoiceFile) {
    if (invoiceFile instanceof File) {
      // file upload from client
      await fileFormUpload(`expedition/${result.data.id}/invoice`, invoiceFile)
    } else if (invoiceFile?.name) {
      // original expedition file cloning
      const clonedFile = await fileSimpleDownload(
        `${apiBaseUrl}expedition/${originalExpeditionId}/invoice`,
        'invoice.pdf',
        (data) => data,
      )
      // cloned file upload
      await fileFormUpload(`expedition/${result.data.id}/invoice`, new File([clonedFile], invoiceFile?.name))
    }
  }
  return result
}

const fetchEditExpedition = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  let carrierOptions = { ...action.payload.carrierOptions }

  if (action.payload.carrierOptions.recipientIdentificationNumber === '') {
    carrierOptions = {
      ...action.payload.carrierOptions,
      recipientIdentificationNumber: null,
    }
  }

  const data = await client
    .query({ query: expeditionUpdateQuery, variables: { ...action.payload, carrierOptions } })
    .then(({ data }) => ({ data: data?.expeditionUpdate }))

  if (action.payload?.deliveryPdfFile) {
    await fileFormUpload(`expedition/${action.payload?.id}/invoice`, action.payload?.deliveryPdfFile)
    // invoice was received but deliveryPdfFile is now empty - deleted
  } else if (!action.payload?.deliveryPdfFile && action.payload?.invoice.name) {
    await client.query({ query: expeditionInvoiceDeleteQuery, variables: { id: action.payload?.id } })
  }
  return data
}

const fetchCancelExpedition = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({ query: expeditionCancelQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.expeditionCancel }))
}

const fetchRollbackExpedition = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({ query: expeditionRollbackQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.expeditionRollback }))
}

const fetchSendToWms = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({ query: expeditionsSendToWmsQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.expeditionsSendToWms }))
}

const fetchProcessToWms = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({ query: expeditionsProcessToWmsQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.expeditionsProcessToWms }))
}

const fetchSendAllToWms = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  let expeditionsIds = []

  // expeditions by criteria
  if (action.payload.criteria) {
    const expeditions: ExpeditionNested[] = await client
      .query({ query: expeditionsGetQuery, variables: { ...action.payload, limit: 1000 } })
      .then(({ data }) => data?.expeditionsGet?.results)
    expeditionsIds = expeditions.map((expedition) => expedition.id)
  }

  // expeditions by array of id
  if (action.payload.ids) {
    expeditionsIds = action.payload.ids
  }

  return client
    .query({ query: expeditionsSendAllToWmsQuery, variables: { expeditions: expeditionsIds } })
    .then(({ data }) => ({ data: data?.expeditionsSendAllToWms }))
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield takeLatest(actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
  yield takeLatest(actionTypes.getExpedition.run, createFetchSaga(actionTypes.getExpedition.run, fetchExpedition))
  yield takeLatest(actionTypes.createExpedition.run, createFetchSaga(actionTypes.createExpedition.run, fetchCreateExpedition))
  yield takeLatest(actionTypes.editExpedition.run, createFetchSaga(actionTypes.editExpedition.run, fetchEditExpedition))
  yield takeLatest(actionTypes.cancelExpedition.run, createFetchSaga(actionTypes.cancelExpedition.run, fetchCancelExpedition))
  yield takeLatest(
    actionTypes.rollbackExpedition.run,
    createFetchSaga(actionTypes.rollbackExpedition.run, fetchRollbackExpedition),
  )
  yield takeLatest(actionTypes.downloadInvoice.run, createFetchSaga(actionTypes.downloadInvoice.run, fetchDownloadInvoice))
  yield takeLatest(actionTypes.sendToWms.run, createFetchSaga(actionTypes.sendToWms.run, fetchSendToWms))
  yield takeLatest(actionTypes.processToWms.run, createFetchSaga(actionTypes.processToWms.run, fetchProcessToWms))
  yield takeLatest(actionTypes.sendAllToWms.run, createFetchSaga(actionTypes.sendAllToWms.run, fetchSendAllToWms))
}
