import { ApolloClient } from '@apollo/client'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import eshopsGetQuery from '@queries/eshopsGetQuery'
import { sagaDebounce } from '@store/helpers'

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({ query: eshopsGetQuery, variables: { ...action.payload, nested: true } })
    .then(({ data }) => ({ data: data?.eshopsGet }))
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield sagaDebounce(200, actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
}
