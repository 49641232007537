import withConnectedRouter from '@HoC/withConnectedRouter'
import React from 'react'

interface Props {
  pathname: string
  search?: string
}

// TODO turn to hook
class ScrollToTop extends React.PureComponent<Props> {
  public componentDidUpdate(prevProps: Props): void {
    if (this.props.pathname !== prevProps.pathname || this.props.search !== prevProps.search) {
      window.scrollTo(0, 0)
    }
  }

  public render(): null {
    return null
  }
}

export default withConnectedRouter(ScrollToTop)
