import { ApolloClient } from '@apollo/client'
import stockChangesGetQuery from '@queries/stockChangesGetQuery'
import { actionTypes } from '@store/stockChangesGrid'
import createFetchSaga from '@utils/store/createFetchSaga'
import { sagaDebounce } from '@store/helpers'

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const stockChanges = await client
    .query({ query: stockChangesGetQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.stockChangesGet }))

  return stockChanges
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield sagaDebounce(200, actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
}
