/**
 * Mixpanel
 * @URL: https://mixpanel.com/
 * -----------------------------------------------------------------------------------------------
 */
import mixpanel, { Dict } from 'mixpanel-browser'
import getRuntimeConfig from '@utils/getRuntimeConfig'
import { actionTypes } from '@store/user/index'
import { take, call, select } from 'redux-saga/effects'
import { BareUser } from '@typings/entities/User'
import { Organisation } from '@typings/entities/Organisation'
import { selectors as organisationSelector } from '@store/organisations'
import { regexEditSuccess, regexCreateSuccess } from './utils'
import { selectors as userSelectors } from '@store/user'
import { selectors as adminSelectors } from '@store/admin'
import { selectors as organisationSelectors } from '@store/organisation'

const mixPanelToken = getRuntimeConfig('FRONTEND__ANALYTICS_MIXPANEL_TOKEN')

export const isAllowedMixpanel = () => {
  const isAllowedAnalytics = localStorage?.allowedStatisticalCookies
  return isAllowedAnalytics && mixPanelToken
}

export function* initMixPanel() {
  try {
    mixpanel.init(mixPanelToken, { debug: true, track_pageview: true, persistence: 'localStorage' })
    const { data: user } = (yield take(actionTypes.load.fetchSucceeded)) as { data: BareUser }
    yield call(identify, user)
  } catch (e) {
    console.error(e)
  }
}

function* track(eventName: string, properties: Dict) {
  try {
    if (isAllowedMixpanel() && mixpanel.config) {
      const user = ((yield select(userSelectors.user)) as State)?.userData
      const admin = ((yield select(adminSelectors.admin)) as State)?.adminData
      const organisations = (yield select(organisationSelector.organisations)) as State
      const organisationId = (yield select(organisationSelectors.organisation)) as string
      const organisation = organisations?.organisationsData?.find(
        (organisation: Organisation) => organisation.id === organisationId,
      )
      const organisationName = organisation?.name || 'Not selected'

      if (!admin && !user) {
        return
      }

      const { id: userId, email: userEmail } = user || admin

      const data = {
        ...properties,
        organisationId,
        organisationName,
        userId,
        userEmail,
      }

      mixpanel.track(eventName, data)
    }
  } catch (e) {
    console.error(e)
  }
}

function* identify(user: BareUser) {
  try {
    if (isAllowedMixpanel() && mixpanel.config) {
      mixpanel.identify(user.id)

      yield call(track, 'User interaction', {
        action: 'Sign In',
        object: 'User',
        objectId: user.id,
        objectName: `${user.firstName} ${user.lastName}`,
        objectType: 'form',
      })
    }
  } catch (e) {
    console.error(e)
  }
}

function* onSetOrganisation(action: Action) {
  const organisationId = action.payload
  const organisations = organisationSelector.organisations((yield select()) as State)
  const organisation = organisations.organisationsData.find((organisation: Organisation) => organisation.id === organisationId)

  yield call(track, 'User interaction', {
    action: 'Set organisation',
    object: 'Organisation',
    objectId: organisationId,
    objectName: organisation?.name,
    objectType: 'select',
  })
}

function* onLocationChange({ payload }: Action) {
  yield call(track, 'User interaction', {
    action: 'Change Page',
    object: 'Page',
    objectId: '',
    objectName: payload?.location.pathname,
    objectType: 'link',
  })
}

function* onCommonGridAddFilter(action: Action) {
  yield call(track, 'User interaction', {
    action: 'Grid add filter',
    object: 'Grid',
    objectId: '',
    objectName: action.gridName,
    objectType: 'list',
  })
}

function* onCommonGridSetColumnConfig(action: Action) {
  yield call(track, 'User interaction', {
    action: 'Grid set columns',
    object: 'Grid',
    objectId: '',
    objectName: action.gridName,
    objectType: 'list',
  })
}

function* onEditAction(action: Action) {
  const object = action.type.match(regexEditSuccess)
  yield call(track, 'User interaction', {
    action: 'edit',
    object: object?.[1],
    objectId: action?.data?.id,
    objectName: object?.[1],
    objectType: 'form',
  })
}

function* onCreateAction(action: Action) {
  const object = action.type.match(regexCreateSuccess)
  yield call(track, 'User interaction', {
    action: 'create',
    object: object?.[1],
    objectId: '',
    objectName: object?.[1],
    objectType: 'form',
  })
}

export const events = {
  onSetOrganisation,
  onLocationChange,
  onCommonGridAddFilter,
  onCommonGridSetColumnConfig,
  onEditAction,
  onCreateAction,
}
