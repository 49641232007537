import { ApolloClient } from '@apollo/client'
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { actionTypes } from './index'
import * as Sentry from '@sentry/browser'
import createFetchSaga, { createSuccessAction } from '@utils/store/createFetchSaga'
import getRuntimeConfig from '@utils/getRuntimeConfig'
import createAxiosRequest from '@utils/createAxiosRequest'
import stockAdviceGetQuery from '@queries/stockAdviceGetQuery'
import stockAdviceCreateQuery from '@queries/stockAdviceCreateQuery'
import stockAdviceDeleteQuery from '@queries/stockAdviceDeleteQuery'
import stockAdviceUpdateQuery from '@queries/stockAdviceUpdateQuery'
import stockAdviceSendQuery from '@queries/stockAdviceSendQuery'
import stockAdviceImport from '@queries/stockAdviceImport'
import productsGetQuery from '@queries/productsGetQuery'
import { StockAdviceApi, StockAdviceItemApi } from '@typings/entities/StockAdvice'
import { ApiInitialError } from '@typings/entities/Error'
import { selectors as organisationSelector } from '@store/organisations'
import { Organisation } from '@typings/entities/Organisation'
import stockAdviceOpenQuery from '@queries/stockAdviceOpenQuery'
import stockAdviceCloseQuery from '@queries/stockAdviceCloseQuery'
import fetchFileAuthorized from '@utils/fetchFileAuthorized'
import { ProductApiRead } from '@typings/entities/Product'

const fetchDetail = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const result: any = await client
    .query({ query: stockAdviceGetQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.stockAdviceGet }))

  // fetch products

  const productIds = (result.data.items || []).map((item: StockAdviceItemApi) => item.product)
  const productsPromise = productIds.length
    ? client
        .query({
          query: productsGetQuery,
          variables: {
            select: ['id', 'productSku', 'internalSku', 'name', 'referenceNumbers', 'workAroundLot', 'eshops', 'active'],
            criteria: {
              id: { in: productIds },
            },
            nested: true,
            limit: 1000,
          },
        })
        .then(({ data }) => data?.productsGet?.results)
    : Promise.resolve([])

  const [products] = await Promise.all([productsPromise])

  if (productIds) {
    for (const index of result.data.items.keys()) {
      const item = result.data.items[index]
      const product = products.find((product: ProductApiRead) => product.id === item.product) || {}
      result.data.items[index] = {
        ...item,
        product: product,
      }
    }
  }

  return result
}

const fetchCreateInbound = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const result = await client
    .query({ query: stockAdviceCreateQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.stockAdvice }))

  const inbound: StockAdviceApi = result?.data
  return inbound
}

const fetchDeleteStockAdvice = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const result = await client.query({ query: stockAdviceDeleteQuery, variables: action.payload })

  return result
}

const fetchOpenStockAdvice = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const result = await client.query({ query: stockAdviceOpenQuery, variables: action.payload })

  return result
}

const fetchCloseStockAdvice = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return await client.query({ query: stockAdviceCloseQuery, variables: action.payload })
}

const fetchUpdateInbound = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const result = await client
    .query({ query: stockAdviceUpdateQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.inboundUpdate }))
  const inbound: StockAdviceApi = result?.data
  return inbound
}

const fetchSendStockAdvice = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  /**
   * uses old-style query that update status to selected one, but because it is always heavily constrained, later special single purpose endpoint for each status change was mase for every entity
   * this status change allows only "new" and "on_hold" statuses to be set, even for cancel and close were later made  single purpose  endpoints, so here it is used as one for consistency
   */
  const SEND_STATUS = 'new'
  const { id } = action.payload
  const result = await client
    .query({ query: stockAdviceSendQuery, variables: { id, status: SEND_STATUS } })
    .then(({ data }) => ({ data: data?.stockAdviceSendQuery }))
  return result
}

const importStockAdvice = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return new Promise(function (resolve, reject) {
    stockAdviceImport(action.payload)
      .then(() => resolve({ message: 'Imported' }))
      .catch((error) => reject(error.response?.data))
  })
}

const importStockAdvices = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  let uploadProgress = 0
  const uploadPromise = new Promise((resolve, reject) => {
    const apiBaseUrl = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')
    const formData = new FormData()
    formData.append('organisation', action.payload.organisation)
    formData.append('file', action.payload.file)
    createAxiosRequest({
      method: 'POST',
      url: `${apiBaseUrl}stock-advice/import`,
      data: formData,
      validateStatus: () => true,
      onUploadProgress: (progress) => {
        if (!progress.total) return
        uploadProgress = progress.loaded / progress.total
      },
    }).then((response) => {
      if (response.status === 204) {
        resolve({ message: 'Imported' })
      } else {
        // TODO remake this
        let message: string | string[]
        if (response.data?.violations && response.data?.violations.length) {
          message = response.data?.violations.map((v: ApiInitialError) => `${v.propertyPath}: ${v.message}`) as string[]
          if (response.data?.message) {
            message.unshift(`${response.data?.message}:`)
          }
        } else {
          message = response.data?.message || response.statusText
        }
        Sentry.captureMessage(String(message))
        reject(message)
      }
    })
  })
  return Promise.resolve({
    uploadPromise,
    getProgress: (): number => uploadProgress,
  })
}

function* adviceImportTemplate(action: Action): Generator {
  const apiBaseUrl = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')
  const url = `${apiBaseUrl}stock-advice/template-item.${action?.payload?.fileFormat}`
  yield put(createSuccessAction(actionTypes.adviceImportTemplate.run, action, null))
  yield call(() => window.location.replace(url))
}

function* advicesImportTemplate(action: Action): Generator {
  const state = (yield select()) as State
  const apiBaseUrl = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')
  const orgIdString = action?.payload?.orgId || ''
  const url = `${apiBaseUrl}stock-advice/template.${action?.payload?.fileFormat}?organisation_id=${orgIdString}`
  yield put(createSuccessAction(actionTypes.getImportTemplateMultiple.run, action, null))
  const organisations = organisationSelector.organisations(state)
  const organisationName = organisations.organisationsData.find(
    (organisation: Organisation): boolean => organisation.id === orgIdString,
  )?.name
  const fileName = organisationName ? `template_${organisationName}` : 'template'
  return fetchFileAuthorized(url, `${fileName}.${action?.payload?.fileFormat}`)
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield takeLatest(actionTypes.loadDetail.run, createFetchSaga(actionTypes.loadDetail.run, fetchDetail))
  yield takeLatest(actionTypes.adviceImportTemplate.run, adviceImportTemplate)
  yield takeLatest(actionTypes.getImportTemplateMultiple.run, advicesImportTemplate)
  yield takeLatest(actionTypes.importStockAdvice.run, createFetchSaga(actionTypes.importStockAdvice.run, importStockAdvice))
  yield takeLatest(actionTypes.importMultiple.run, createFetchSaga(actionTypes.importMultiple.run, importStockAdvices))
  yield takeLatest(actionTypes.createInbound.run, createFetchSaga(actionTypes.createInbound.run, fetchCreateInbound))
  yield takeLatest(actionTypes.editInbound.run, createFetchSaga(actionTypes.editInbound.run, fetchUpdateInbound))
  yield takeLatest(actionTypes.deleteStockAdvice.run, createFetchSaga(actionTypes.deleteStockAdvice.run, fetchDeleteStockAdvice))
  yield takeLatest(actionTypes.openStockAdvice.run, createFetchSaga(actionTypes.openStockAdvice.run, fetchOpenStockAdvice))
  yield takeLatest(actionTypes.closeStockAdvice.run, createFetchSaga(actionTypes.closeStockAdvice.run, fetchCloseStockAdvice))
  yield takeLatest(actionTypes.sendStockAdvice.run, createFetchSaga(actionTypes.sendStockAdvice.run, fetchSendStockAdvice))
}
