import { takeLatest } from 'redux-saga/effects'

const promiseControlSymbol = Symbol.for('promiseControl')

function* processFetchFailed(action: Action): Generator {
  if (action[promiseControlSymbol]) {
    action[promiseControlSymbol].reject(action)
  }
}

function* processFetchSucceeded(action: Action): Generator {
  if (action[promiseControlSymbol]) {
    action[promiseControlSymbol].resolve(action)
  }
}

function* commonFetchSaga(): Generator {
  if (typeof window === 'undefined') return
  yield takeLatest((action: Action) => /_FETCH_FAILED$/.test(action.type), processFetchFailed)
  yield takeLatest((action: Action) => /_FETCH_SUCCEEDED$/.test(action.type), processFetchSucceeded)
}

export default commonFetchSaga
