import React from 'react'
import loadable from '@loadable/component'
import pages from '@pages/index'
import { Redirect } from 'react-router-dom'

const SettingsLayout = loadable(() => import('./SettingsLayout'), {})
const AdminLayout = loadable(() => import('./Admin'), {})
const UserLayout = loadable(() => import('./User'), {})

export type LayoutType = 'Admin' | 'User' | 'Settings' | null

type LayoutSwitcherProps = {
  type: LayoutType
  children: React.ReactNode
}

const LayoutSwitcher = (props: LayoutSwitcherProps): JSX.Element => {
  if (props.type === 'Settings') return <SettingsLayout {...props} />
  if (props.type === 'Admin') return <AdminLayout {...props} />
  if (props.type === 'User') return <UserLayout {...props} />
  return <Redirect to={pages.userLogin.route.toUrl()} />
}

export default LayoutSwitcher
