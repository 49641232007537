import gql from 'graphql-tag'

const reportReservationsPartiallyDelivered = gql`
  query reportReservationGet($organisation: String) {
    reportReservationGet(input: { organisation: $organisation })
      @rest(type: "ReportReservationResult", method: "POST", path: "reservation/list/partially-delivered") {
      count
    }
  }
`

export default reportReservationsPartiallyDelivered
